import axios from "axios";

let baseUrlClear = "/";
if (window.myAppConfig)
  if (window.myAppConfig.REACT_APP_ENV === "dev") {
    baseUrlClear = `http://${window.myAppConfig.REACT_APP_API_SERVER}`;
  } else {
    baseUrlClear = `https://${window.myAppConfig.REACT_APP_API_SERVER}`;
  }
else if (process.env.REACT_APP_ENV === "dev")
  baseUrlClear = `http://${process.env.REACT_APP_API_SERVER}`;
else baseUrlClear = `https://${process.env.REACT_APP_API_SERVER}`;

const axiosInstance = axios.create({
  baseURL: baseUrlClear,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export { axiosInstance };
