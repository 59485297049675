import { Button, DatePicker, Popover, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import {
  // FREQUENCY,
  resetDateFilterAfter,
  resetDateFilterBefore,
} from "../../utils/utils";

export const DatePickerPopover = ({
  openDate,
  setOpenDate,
  viewDataFilter,
  dateAfterFilter,
  dateBeforeFilter,
  handleChangeDateBefore,
  handleChangeDateAfter,
  //handleSetDateFilters,
}) => {
  // Object.keys(FREQUENCY).forEach((key, i) => {
  //   frequentlyUsedFilters.push(
  //     <span key={i} onClick={() => handleSetDateFilters(key)}>
  //       {FREQUENCY[key].title}
  //     </span>
  //   );
  // });

  return (
    <Space.Compact title="Выберите интервал">
      <Popover
        placement="bottomLeft"
        open={openDate}
        onOpenChange={() => {
          setOpenDate(!openDate);
        }}
        title={false}
        content={
          <div>
            <Space direction="vertical">
              <span>Начало</span>
              <DatePicker
                // showTime
                style={{ borderRadius: 2 }}
                format="DD-MM-YYYY"
                onChange={handleChangeDateAfter}
                placeholder="Дата от"
                value={
                  dateAfterFilter ? resetDateFilterAfter(dateAfterFilter) : null
                }
              />
              <span>Окончание</span>
              <DatePicker
                // showTime
                style={{ borderRadius: 2 }}
                format="DD-MM-YYYY"
                onChange={handleChangeDateBefore}
                placeholder="Дата до"
                value={
                  dateBeforeFilter
                    ? resetDateFilterBefore(dateBeforeFilter)
                    : null
                }
              />
            </Space>
          </div>
        }
        trigger="click"
      >
        <Button style={{ display: "flex", borderRadius: 2 }}>
          <CalendarOutlined
            style={{
              color: "#6679A9",
            }}
          />
          {Boolean(viewDataFilter) && (
            <h4 style={{ padding: "0 5px" }}>{viewDataFilter}</h4>
          )}
        </Button>
      </Popover>
    </Space.Compact>
  );
};

export default DatePickerPopover;
