import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false) => {
  if (!filters) filters = {};
  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.cursor = cursor;
  }
  return filters;
};

export const postClient = createAsyncThunk(
  "postClient",
  async ({ studio_y_id, y_phone }, { rejectWithValue }) => {
    try {
      let formattedPhone = y_phone.startsWith("+") ? y_phone : `+${y_phone}`;
      const { data } = await axiosInstance.post(
        `/studio_clients/studio/${studio_y_id}/clients/search-by-phone/`,
        { y_phone: formattedPhone }
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  client: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
  },
  loaders: {
    common: false,
  },
};

const clientsSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearClient: (state) => {
      state.client = null;
    },
  },

  extraReducers: (builder) => {
    // postClient
    builder.addCase(postClient.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(postClient.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.client = payload;
      state.success.success = true;
    });
    builder.addCase(postClient.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
  },
});
export const { clearError, clearClient } = clientsSlice.actions;
export default clientsSlice.reducer;
