import React from "react";
import { Alert, Space } from "antd";

export default function displayErrors(errors) {
  if (!errors) return null;
  if (typeof errors === "string") return viewNetworkError(errors);
  else if (
    errors?.type === "client_error" ||
    errors?.type === "informational" ||
    errors?.type === "server_error" ||
    errors?.type === "unknown_error"
  )
    return (
      <Space direction="vertical" style={{ display: "flex", margin: "10px 0" }}>
        {errors?.errors?.map(({ detail, attr }, i) =>
          i <= 2 ? (
            <Alert
              key={i}
              message={`поле: ${attr}: ${detail}`}
              type="error"
              showIcon
            />
          ) : (
            ""
          )
        )}
      </Space>
    );

  return (
    <Alert
      message={errors?.errors[0]?.code}
      description={errors?.errors[0]?.detail}
      style={{ textAlign: "left" }}
      type="error"
      showIcon
    />
  );
}

const viewNetworkError = (error) => {
  let err = {};
  if (error === "ERR_NETWORK")
    err = {
      title: "Ошибка подключения",
      message:
        "Возможно проблема с подключением к серверу или сертификат сервера не принимается браузером.",
    };
  else
    err = {
      title: "Ошибка подключения",
      message: "Нет связи с сервером, попробуйте обновить страницу",
    };
  return (
    <Alert
      message={err.title}
      description={err.message}
      style={{ textAlign: "left", margin: "10px 0" }}
      type="error"
      showIcon
    />
  );
};
