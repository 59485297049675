import { useState, useEffect } from "react";
import { Drawer, Typography } from "antd";
import { useDispatch } from "react-redux";

export const InfoModal = ({ openModal, setOpenModal, title, text }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Drawer
        styles={{
          body: { padding: "15px 64px" },
        }}
        open={openModal}
        onCancel={() => {
          dispatch(setOpenModal());
        }}
        onClose={() => {
          dispatch(setOpenModal());
        }}
        footer={null}
        width={500}
        maskClosable={true}
      >
        <>
          <Typography.Title level={3}>{title}</Typography.Title>
          <Typography.Text>{text}</Typography.Text>
        </>
        {/* {loader && <PreloaderNew />} */}
      </Drawer>
    </>
  );
};
