import React from "react";
import Warning from "../../images/iconWarning.svg";
import styles from "./styles.module.css";

export const Plug = () => {
  return (
    <div className={styles.containerWarning}>
      <div className={styles.container}>
        <img className={styles.img} src={Warning} alt="Warning" />
        <h5 className={styles.title}>
          Кажется, доступ к этой странице запрещен
        </h5>
        <p className={styles.text}>
          Просто так сюда не попасть. Скорее всего, вы получили ссылку на тот
          функционал, который вам и не нужен совсем.
        </p>
      </div>
    </div>
  );
};
