import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getCorrections = createAsyncThunk(
  "getCorrections",
  async (
    { studio_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_corrections/studio/${studio_y_id}/corrections/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const getTypeDropDown = createAsyncThunk(
  "getTypeDropDown",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_corrections/studio/${studio_y_id}/correction_types/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const postCorrections = createAsyncThunk(
  "postCorrections",
  async ({ studio_y_id, params }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_corrections/studio/${studio_y_id}/corrections/`,
        params
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchCorrections = createAsyncThunk(
  "patchCorrections",
  async ({ studio_y_id, id, params }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_corrections/studio/${studio_y_id}/corrections/${id}/`,
        params
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCorrections = createAsyncThunk(
  "deleteCorrections",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_corrections/studio/${studio_y_id}/corrections/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFailPhoto = createAsyncThunk(
  "deleteFailPhoto",
  async ({ studio_y_id, id, uid }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_fail_photos/studio/${studio_y_id}/fail/${id}/photos/${uid}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  corrections: null,
  typeDropDown: null,
  openCorrections: false,
  nextPage: null,
  success: {
    success: false,
    create: false,
    edit: false,
    successPhoto: false,
  },
  errors: {
    error: null,
    edit: null,
    create: null,
    delete: null,
    typeDropDown: null,
    deletePhoto: null,
  },
  loaders: {
    typeDropDown: false,
    common: false,
  },
};

const correctionsSlice = createSlice({
  name: "corrections",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorEdit: (state) => {
      state.errors.edit = null;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    setOpenCorrections: (state) => {
      state.openCorrections = !state.openCorrections;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
    clearErrorTypeDropDown: (state) => {
      state.errors.typeDropDown = null;
    },
  },

  extraReducers: (builder) => {
    // getCorrections
    builder.addCase(getCorrections.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getCorrections.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.corrections = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getCorrections.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    //getTypeDropDown
    builder.addCase(getTypeDropDown.pending, (state) => {
      state.loaders.typeDropDown = true;
    });
    builder.addCase(getTypeDropDown.fulfilled, (state, { payload }) => {
      state.typeDropDown = payload;
      state.loaders.typeDropDown = false;
    });
    builder.addCase(getTypeDropDown.rejected, (state, action) => {
      state.loaders.typeDropDown = false;
      state.errors.typeDropDown = action.payload;
    });
    // postCorrections
    builder.addCase(postCorrections.pending, (state) => {
      state.loaders.common = true;
      state.errors.create = null;
    });
    builder.addCase(postCorrections.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.create = true;
      // state.fails = payload;
    });
    builder.addCase(postCorrections.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.create = action?.payload;
    });
    // patchCorrections
    builder.addCase(patchCorrections.pending, (state) => {
      state.loaders.common = true;
      state.errors.edit = null;
    });
    builder.addCase(patchCorrections.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.edit = true;
      // state.fails = payload;
    });
    builder.addCase(patchCorrections.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.edit = action?.payload;
    });
    // deleteCorrections
    builder.addCase(deleteCorrections.pending, (state) => {
      state.loaders.common = true;
      state.errors.delete = null;
    });
    builder.addCase(deleteCorrections.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.success = true;
      // state.fails = payload;
    });
    builder.addCase(deleteCorrections.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.delete = action?.payload;
    });
    // deleteFailPhoto
    builder.addCase(deleteFailPhoto.pending, (state) => {
      state.loaders.common = true;
      state.errors.deletePhoto = null;
    });
    builder.addCase(deleteFailPhoto.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.successPhoto = true;
      // state.fails = payload;
    });
    builder.addCase(deleteFailPhoto.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.deletePhoto = action?.payload;
    });
  },
});
export const {
  clearError,
  setOpenCorrections,
  clearErrorEdit,
  clearErrorCreate,
  clearSuccess,
  clearSuccessEdit,
  clearSuccessCreate,
  clearErrorTypeDropDown,
} = correctionsSlice.actions;
export default correctionsSlice.reducer;
