import React from "react";
import styles from "./styles.module.css";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.text}>
        GlamGuru.ru - эффективная автоматизация бизнеса
      </p>
    </div>
  );
};
