import React from "react";
import { Button, Typography } from "antd";
import styles from "./styles.module.css";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const SuccessModalSide = ({ close, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <CheckCircleOutlined style={{ fontSize: 50, color: "#327FEF" }} />
        <Title style={{ textAlign: "center" }} level={1}>
          {title}
        </Title>
        <Button
          className={styles.button}
          onClick={() => close()}
          style={{
            width: 243,
          }}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
};
