import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./login";
import userSlice from "./user";
import failsSlice from "./fails";
import studiosSlice from "./studios";
import clientsSlice from "./clients";
import staffSlice from "./staff";
import correctionsSlice from "./corrections";
import networkComplaintsSlice from "./networkComplaints";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  fails: failsSlice,
  studios: studiosSlice,
  clients: clientsSlice,
  staff: staffSlice,
  corrections: correctionsSlice,
  networkComplaints: networkComplaintsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
