export const getComplaintsNetworkData = ({ networkComplaints }) =>
  networkComplaints.networkComplaints;
export const getFailsLoading = ({ networkComplaints }) =>
  networkComplaints.loaders.common;
export const getErrorEdits = ({ networkComplaints }) =>
  networkComplaints.errors.edit;
export const getErrorCreate = ({ networkComplaints }) =>
  networkComplaints.errors.create;
export const getErrorDelete = ({ networkComplaints }) =>
  networkComplaints.errors.delete;
export const getError = ({ networkComplaints }) =>
  networkComplaints.errors.error;
export const getSuccess = ({ networkComplaints }) =>
  networkComplaints.success.success;
export const getStaffDropDownSelector = ({ networkComplaints }) =>
  networkComplaints.staffDropDown;
export const getStudioDropDownSelector = ({ networkComplaints }) =>
  networkComplaints.studioDropDown;
export const getOpenComplaintsNetworkSelector = ({ networkComplaints }) =>
  networkComplaints.openComplaintsNetwork;
export const getSuccessCreate = ({ networkComplaints }) =>
  networkComplaints.success.create;
export const getSuccessEdit = ({ networkComplaints }) =>
  networkComplaints.success.edit;
export const getEventsNextPage = ({ networkComplaints }) =>
  networkComplaints.nextPage;
export const getStudioData = ({ networkComplaints }) =>
  networkComplaints.networkComplaint;
