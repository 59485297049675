import styles from "./styles.module.css";
import { Modal, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";

export const ModalComponent = ({
  open,
  title,
  onOk,
  onCancel,
  body,
  closeIcon = false,
}) => {
  return (
    <>
      <Modal
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        closable={Boolean(!closeIcon)}
        closeIcon={<CloseOutlined className={styles.modalCloseIcon} />}
        centered
        footer={false}
        styles={{
          header: { display: "none" },
          mask: {
            backdropFilter: "blur(50px)",
          },
          content: {
            boxShadow: "0 0 30px #999",
          },
        }}
      >
        <div
          style={{
            padding: 40,
          }}
        >
          <Typography.Title
            level={3}
            style={{
              color: "#4B4F55",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            {title}
          </Typography.Title>
          {body}
        </div>
      </Modal>
    </>
  );
};
