export const getCorrectionsData = ({ corrections }) => corrections.corrections;
export const getCorrectionsLoading = ({ corrections }) =>
  corrections.loaders.common;
export const getErrorEdits = ({ corrections }) => corrections.errors.edit;
export const getErrorCreate = ({ corrections }) => corrections.errors.create;
export const getErrorDelete = ({ corrections }) => corrections.errors.delete;
export const getError = ({ corrections }) => corrections.errors.error;
export const getSuccess = ({ corrections }) => corrections.success.success;
export const getStaffDropDownSelector = ({ corrections }) =>
  corrections.staffDropDown;
export const getTypeDropDownSelector = ({ corrections }) =>
  corrections.typeDropDown;
export const getOpenCorrectionsSelector = ({ corrections }) =>
  corrections.openCorrections;
export const getSuccessCreate = ({ corrections }) => corrections.success.create;
export const getSuccessEdit = ({ corrections }) => corrections.success.edit;
export const getEventsNextPage = ({ corrections }) => corrections.nextPage;
