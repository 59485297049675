import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false) => {
  if (!filters) filters = {};
  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.cursor = cursor;
  }
  return filters;
};

export const getStaffDropDown = createAsyncThunk(
  "getStaffDropDown",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/?limit=500`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffDropDownNetwork = createAsyncThunk(
  "getStaffDropDownNetwork",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_staff/network/${studio_y_id}/staff/?limit=2000`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaff = createAsyncThunk(
  "getStaff",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  staffDropDown: null,
  staffDropDownNetwork: null,
  staff: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
    staffDropDownNetwork: null,
  },
  loaders: {
    staffDropDownNetwork: false,
    common: false,
  },
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetMaster: (state) => {
      state.staff = null;
    },
  },

  extraReducers: (builder) => {
    //getStaffDropDown
    builder.addCase(getStaffDropDown.pending, (state) => {
      state.loaders.staffDropDown = true;
    });
    builder.addCase(getStaffDropDown.fulfilled, (state, { payload }) => {
      state.staffDropDown = payload;
      state.loaders.staffDropDown = false;
    });
    builder.addCase(getStaffDropDown.rejected, (state, action) => {
      state.loaders.staffDropDown = false;
      state.errors.staffDropDown = action.payload;
    });
    //getStaff
    builder.addCase(getStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStaff.fulfilled, (state, { payload }) => {
      state.staff = payload;
      state.loaders.common = false;
    });
    builder.addCase(getStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action.payload;
    });
    //getStaffDropDown
    builder.addCase(getStaffDropDownNetwork.pending, (state) => {
      state.loaders.staffDropDownNetwork = true;
    });
    builder.addCase(getStaffDropDownNetwork.fulfilled, (state, { payload }) => {
      state.staffDropDownNetwork = payload;
      state.loaders.staffDropDownNetwork = false;
    });
    builder.addCase(getStaffDropDownNetwork.rejected, (state, action) => {
      state.loaders.staffDropDownNetwork = false;
      state.errors.staffDropDownNetwork = action.payload;
    });
  },
});
export const { resetMaster } = staffSlice.actions;
export default staffSlice.reducer;
