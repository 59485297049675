import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

export const getStudio = createAsyncThunk(
  "getStudio",
  async (y_id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/studios/${y_id}/`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getStudioRecords = createAsyncThunk(
  "getStudioRecords",
  async ({ studio_y_id, y_phone }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_records/studio/${studio_y_id}/records/?y_phone=${y_phone}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getServiceRecord = createAsyncThunk(
  "getServiceRecord",
  async ({ studio_y_id, id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_records/studio/${studio_y_id}/records/${id}/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  studio: null,
  studioRecords: null,
  serviceRecord: null,
  studioMenuTitle: null,
  errors: null,
  loaders: {
    common: false,
  },
};

const studiosSlice = createSlice({
  name: "studios",
  initialState,
  reducers: {
    clearErrorStudio: (state) => {
      state.errors = null;
    },
    setStudioMenu: (state, action) => {
      state.studioMenuTitle = action.payload;
    },
  },

  extraReducers: (builder) => {
    // getStudio
    builder.addCase(getStudio.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getStudio.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.studio = payload;
    });
    builder.addCase(getStudio.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
    // getStudioRecords
    builder.addCase(getStudioRecords.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getStudioRecords.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.studioRecords = payload;
    });
    builder.addCase(getStudioRecords.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
    // getServiceRecord
    builder.addCase(getServiceRecord.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getServiceRecord.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.serviceRecord = payload;
    });
    builder.addCase(getServiceRecord.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
  },
});
export const { clearErrorStudio, setStudioMenu } = studiosSlice.actions;
export default studiosSlice.reducer;
